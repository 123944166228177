import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../assets/logo.jpg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Home from "./Home";

const Header = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const refs = useRef(null);
  const refsOfUl = useRef(null);
  const [savedScrollPosition, setSavedScrollPosition] = useState(0);

  useEffect(() => {
    if(menuOpen){
      gsap.from(refs.current, {
      
        duration: 1,
        opacity: 0,
        y: 0,
        ease: "power4.out",
        
      });
  
      gsap.from(refsOfUl.current.children, { 
        x:100,
        duration: 2,
        opacity: 0,
        stagger: 0.2,

      });
      

      
    }
  },[menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      // Save current scroll position
      setSavedScrollPosition(window.scrollY);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [menuOpen]);

  const handleNavClick = () => {
    setMenuOpen(false);
    // Reset to top of page when navigating
    window.scrollTo(0, 0);
  };

  return (
    <div className="  mt-[20px] ">
      <div
        className={`fixed top-0   border-b-[0.2px] border-white  z-[10]    sm:w-full sm:px-16 py-3 font-['Neue Montreal'] bg-zinc-50   sm:flex sm:justify-between sm:items-center opacity-100  ${
          isHome ? "text-black" : "text-black"
        }`}
      >
        <div className="flex justify-between items-center gap-[200px] ">
          <p className={`${isHome && ""} text-3xl`}>
            <Link to="/"><img className="h-[7vh] sm:h-[10vh] " alt="no render" src={logo} /></Link>
          </p>
          <div onClick={() => setMenuOpen(true)} className="md:hidden pr-[3vw]">
            <i className="ri-menu-line text-3xl "></i>
          </div>
        </div>
        {
          <div className="hidden md:flex -ml-[50px] gap-[2vw]  sm:gap-10">
            {["Home", "About", "Services", "Projects", "ContactUs"].map(
              (item, index) => {
                const path = item === "Home" ? "/" : `/${item.toLowerCase()}`;
                return (
                  <NavLink
                    key={index}
                    to={path}
                    className={({ isActive }) =>
                      `text-lg font-light capitalize ${
                        index === 4 && "sm:ml-32"
                      } ${
                        isActive ? "border-b-2 border-black" : " border-black"
                      } 
                   `
                    }
                  >
                    {item}
                  </NavLink>
                );
              }
            )}
          </div>
        }
      </div>
      {menuOpen && (
        <div
          ref={refs}
          className="fixed inset-0 bg-black  flex flex-col items-center justify-center text-white z-50"
        >
          
          <button
            className="absolute top-5 right-5 text-white"
            onClick={() => setMenuOpen(false)}
          >
            <i className="ri-close-line"></i>
          </button>

          {/* Mobile Navigation Links */}
          <ul ref={refsOfUl} className="text-2xl space-y-6">
            {["Home", "About", "Services", "Projects", "ContactUs"].map(
              (item, index) => {
                const path = item === "Home" ? "/" : `/${item.toLowerCase()}`;
                return (
                  <li key={index}>
                    <NavLink
                      to={path}
                      onClick={handleNavClick}
                      className="hover:text-gray-400"
                    >
                      {item}
                    </NavLink>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
