import React from 'react'

const Footer = () => {
  return (
    <div className='font-roboto bg-black text-white w-full h-auto sm:h-[50vh] mt-1 sm:mt-[100px]'>
        <div className='sm:flex sm:justify-between gap-[100px] mx-[60px] py-7'>
            <div>
                <p className="text-3xl text-white">Jayz Energy</p>
            </div>
            <div className='pb-4'>
                <h3>Working with talented and driven people is our passion ;</h3>
                <h3>We'd love to build something great together.</h3>
                <h1 className='text-3xl w-[200px] font-bold my-[30px] border-b-2 border-white'>Contact Us</h1>
                <h3>Ontario , CANADA</h3>
                <h3>+1 365 275 5299</h3>
                <h3>info@jayzenergy.com</h3>
                <h4 className='mt-[30px] text-[10px]'>©Jayz Energy. All Rights Reserved.</h4>
            </div>
        </div>
    </div>
  )
}

export default Footer